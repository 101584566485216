import React from 'react';
import Logo from 'static/images/logo.png'
import ScreenshotEng from 'static/images/screenshoteng.png'
import ScreenshotEsp from 'static/images/screenshotesp.png'
import AppStoreLogo from 'static/images/appstore.png'
import { useTranslation } from 'react-i18next';
import CirculosAbajo from 'static/images/perfilesAbajo.png'

const Index = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className='content'>
      <div className='header'>
        <select name="language-picker-select" id="language-picker-select" value={i18n.language} onChange={e => changeLanguage(e.target.value)}>
            <option lang='en' value='en' onClick={() => changeLanguage('en')}>EN</option>
            <option lang='es' value='es' onClick={() => changeLanguage('es')}>ES</option>
            <option lang='fr' value='fr' onClick={() => changeLanguage('fr')}>FR</option>
            <option lang='pt' value='pt' onClick={() => changeLanguage('pt')}>PT</option>
        </select>
      </div>
      <div className='logo'>
        <a href='https://apps.apple.com/co/app/kooler-video-messages/id1513188069'>
          <img src={Logo} alt='logo'></img>
        </a>
      </div>
      <div>
        <img src={i18n.language === 'es' ? ScreenshotEsp: ScreenshotEng} alt='screenshot'></img>
      </div>
      <div className='appstore'>
        <a href='https://apps.apple.com/co/app/kooler-video-messages/id1513188069'>
          <img src={AppStoreLogo} alt='apple' width='250'></img>
        </a>
      </div>
      <div className='main'>
        <div className='text'>
          <h1> {t('videoMessages')}</h1>
          <p>{t('pf1')}</p>
        </div>
        <img src={CirculosAbajo} height='150' alt='alt'></img>
      </div>
      <div className='terms'>
        <a href='/terms'>{t('terms')}</a>
        <div>Copyright © 2020 - All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default Index;