import React from 'react';
import { Route } from 'react-router-dom';
import { KoolerPages } from 'router/pages.js';

const Router = () => {
  return (
    <>
      {
        KoolerPages && KoolerPages.map(page => {
          return (<Route exact {...page} />)
        })
      }
    </>
  );
}

export default Router;