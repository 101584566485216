import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from 'router/Router';
import logo from 'static/images/logo.png';

const App = () => {
  return (
    <Suspense fallback={<Loader/>}>
      <BrowserRouter >
        <Router />
      </BrowserRouter>
    </Suspense>
  );
}
const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

export default App;