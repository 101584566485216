import Index from 'pages/Index';
import Terms from 'pages/Terms';

const KoolerPages = [{
  key: 0,
  title: 'Holi',
  path: '/',
  component: Index,
}, {
  key: 1,
  title: 'Terms and Conditions',
  path: '/terms',
  component: Terms,
}];
export { KoolerPages };
