import React from "react";

const Terms = () => {
  const style = {
    margin: "0px auto",
    maxWidth: "80%",
    textAlign: "justify",
    textJustify: "inter-word",
    position: "relative",
    background: "white",
    padding: "50px",
    color: "black",
  };

  return (
    <div style={style}>
      <div>
        <h3>Terms of Service and Privacy Policy</h3>
        <p>
          The following terms and conditions are part of the management policy
          and information management for access and use of the services offered
          by Okii SAS, within the Holi application. By accessing the application
          or app Holi website found in www.holiapp.live, either through a
          computer or mobile device or computer you accept that you will be
          bound by these conditions. Anyone who does not accept these terms and
          conditions and privacy policy is incorporated at the end of the
          document, which are mandatory and binding, must refrain from using the
          application and / or services. If you use the application, this will
          entail the full acceptance of the conditions set out in the General
          Terms and Conditions. For such use of the site and / or its services,
          it will force you to expressly comply with them, can not plead
          ignorance of these General Terms and Conditions and Privacy Policy in
          the future. This application and its contents belong to the company
          Okii SAS, which is why the total or partial reproduction of its
          contents without the prior written permission of Okii SAS is
          prohibited. LEGAL CAPACITY This agreement is an electronic contract
          that sets out the legally binding terms for the parties that the user
          must agree to use the application. Our services are available for
          purchase by persons having legal capacity. You can not use this
          application people who do not have that capacity. The acts that the
          minors carry on this site are the responsibility of their parents and
          / or guardians, guardians or curators, and therefore deemed made by
          them in the exercise of legal representation with that count. If you
          create an account or to access and use the application, you warrant
          that you have the capacity to enter into this agreement and to comply
          with the terms and conditions set forth herein. Note that the use of
          the application and the services offered here may be prohibited or
          restricted in some countries, therefore, if you use the service
          outside the Republic of Colombia, you are responsible for complying
          with the laws and regulations of the territory from which access or
          use this application. By creating an account, access the application
          or using our services, you warrant that you have never been convicted
          of crimes against economic wealth, against freedom, integrity and
          sexual education and against moral integrity as well as that are not
          required or registered as a sex offender by any government entity.
        </p>
        <p>
          TERMS: In order to use Holi, you must log in with your Facebook,
          authorizing us to access and use certain information from the linked
          account, including, but not limited profile, Facebook friends they
          have in common the application , among others. To learn how to obtain
          and use this information please see our privacy policy. Okii SAS,
          require users to limit all activities related to the use of the
          application for the purposes we allow legally. No user can post or
          transmit through this application: images, text, videos, hyperlinks or
          any material that violates or infringes in any way upon the rights of
          third parties, like any unlawful, threatening, vulgar, obscene,
          profane, abusive , advertising, defamatory, invasive regarding the
          right to privacy or anyone going against morals, ethics and morality,
          those aimed at asking for money, involving the transmission of junk
          mail, that promote illegal or unauthorized copy the copyrighted work
          of another person, video, audio or images of another person without
          their permission or a minor. Any conduct by a user, according to Okii
          SAS, restrict or inhibit the use of the application is strictly
          prohibited, reason why the user account will be terminated without
          prejudice of the legal actions that may be.
        </p>
        <p>
          When using Holi, you agree that you wont create any illegal or
          prohibited content included in the following list. PROHIBITED
          CONTENT:Any offensive or obscene content such as and not limited to
          racism, pornography,sexism,etnism,hatred or any other content against
          human dignity. Content that promotes or illegal activities. Content
          that promotes another personâ€™s copyright work. Holi will have the
          right to take legal action on discretion against violators of this
          terms and remove them from the service and their memberships.
        </p>
        <p>
          DURATION: This Agreement shall remain in force and take effect while
          you use the application or have an existing account Okii app. The
          account may be disabled at any time for any reason. Okii SAS may
          cancel or suspend your account at any time and without notice if
          deemed to have violated any provision of this Agreement, or for any
          reason, with or without justification. Once suspended the account, the
          user will not be entitled to any refund of payments for services not
          used in application purchases. Okii SAS is not obliged to disclose the
          reason or reasons that led to suspend or terminate the account, unless
          by law ordered. After the suspension or termination of the account,
          all the terms of this Agreement survive such termination and continue
          in force and have effect except those which by their nature expire.
        </p>
        <p>
          USE: The application and website are managed by Okii SAS located in
          MedellÃ­n, Colombia. Whether through confirmation of the corresponding
          field in access or process if you make effective use of the
          application, they will be accepted the conditions of use of the
          application. In this case, you recognize that we Okii SAS, consider
          the use of our services as recognition of the conditions of use. To
          acquire the services offered in the application, the Registered Users
          must provide certain personal data. Your personal information is
          processed and stored on servers or magnetic media that maintain high
          safety standards and both physical and technological protection. For
          more information about the privacy of personal data and cases to be
          revealed personal information, you can check our Privacy Policy at
          any, which are at the end of the document. The General Terms and
          Conditions may be amended by Okii SAS at any time; these amendments
          shall take effect immediately. Although we strive to provide our
          service without technical hassles, particularly maintenance work,
          further development of the application, updates and other issues may
          limit and / or partially interrupt the usability of the application.
          Under certain conditions may cause loss of the information provided.
          Okii SAS assumes no guarantee on service availability or lack of
          technical inconvenience or loss of data or information. All content of
          this application, including text, photographs, images, logos, icons or
          other information are the sole and exclusive property of Okii SAS
          therefore, any person accessing and / or use the application should
          refrain from copying, extract and / or reuse parts thereof without the
          express prior consent of Okii SAS. In addition to the above content
          are intellectual property of Okii SAS marks (word or figurative)
          industrial designs and any other element of intellectual property
          appearing in the application, why none of these elements appear in the
          application can be used by any user in connection with any product or
          service that is not provided by Okii SAS nor so as to produce
          confusion with users or discredits Okii SAS or any act considered as
          unfair competition. Users are responsible for maintaining the
          confidentiality of the username and password you designate during the
          registration process why you are solely responsible for any acts that
          occur under your username, password and account. It is the user's
          obligation to immediately notify Okii SAS any unauthorized use or
          disclosure of your user name, password or any security violation. The
          notification may be made to the following email: support@holiapp.live.
          The interactions you have with other users are solely your
          responsibility. Okii app does not review or investigate criminal
          background screening of its members, nor verifies the accuracy of the
          information provided by users. Okii SAS may perform any or criminal
          background check can provide the appropriate legal entity all
          information provided in cases of records or account tracking sex
          offenders. Okii SAS is not responsible for the behavior of users
          accessing the application. In any case and under any circumstances,
          Okii SAS, its subsidiaries, branches or partners will be directly or
          indirectly responsible for any damage of any kind arising in relation
          to the use of the application, including, but not limited, death,
          bodily injury or mental, economic losses, thefts, among others,
          arising out of or related to user behavior or a third party in
          relation to the use of the application. The user agrees to take all
          necessary precautions possible interactions you get to have with other
          users, especially in communications or face meetings or any
          communication that is made outside the application. Additional sole
          responsibility of the user, review and consider the terms and
          conditions before using the application to avoid any breach of
          security. You should not provide personal or financial information,
          such as social security number, credit card or bank account, address
          of residence, work, among others. To access the services of the
          application must be in an area with Wi-Fi or have their activated
          mobile data.
        </p>
        <p>
          USER CONTENT POSTED You are solely responsible for the information and
          content that you share, post, transmit or put at the disposal of other
          users, such as messages in chat, videos, photos, texts or information
          provided in your profile, either publicly or private. You represent
          and warrant that all information provided in the creation of the
          account, including account information of Facebook, is accurate and
          true, and also that the information that is inaccurate, incomplete or
          false be updated. You agree that Okii SAS may, but is not required to
          check and control any type of content that users post through the use
          of the application, why may remove any information or content,
          partially or completely, that under his judgment, consider a violation
          of this agreement or goes against the good name of Okii app and Okii
          SAS. With the publication of information, the user automatically
          authorizes Okii SAS, partners and affiliates, to use, copy, store,
          reproduce, publish, modify and distribute such information. You
          acknowledge that all content and information that is used to access
          the application and the services it provides, can be seen by other
          users involved in the application.
        </p>
        <p>
          PROHIBITIONS: Okii SAS may investigate, suspend or terminate your
          account if you had a behavior, which we believe is deemed illegal or
          inappropriate. Among the actions that are prohibited to perform when
          using the application are: Impersonating someone else, impersonating
          another. Harassing in any way to another user or person. Affirming the
          support or endorsement of Okii SAS or any of its partners, in any of
          your statements, without the explicit, prior written consent from us.
          Use the application to the commission of any unlawful act. Extort,
          capture or request money from other users. Collect usernames or email
          addresses in order to send unsolicited information. Transmit any
          content that contains viruses or other computer code designed to
          damage, interrupt or limit the proper functioning of software or
          hardware. Modify, adapt, distribute, sell or perform any act with the
          software used in the application.
        </p>
        <p>
          FINAL PROVISIONS: Eventually Holi offers additional products and
          services for purchase, if you decide to purchase, data credit card is
          required. Such products or services may include a free trial period;
          At the end of this period, the subscription price will be debited. To
          avoid any charges, cancellation must be made as follows: a. Before
          finishing the trial period and b. If it is a recurring subscription
          will be billed continuously until canceled 10 business days prior to
          renewal. Holi may revise the price of products and services offered
          through the application at any time, in which case the new price list
          on our website will be published. You acknowledge that Holi will not
          reimburse under any circumstances, nor makes any compensation for
          unused services when you close or cancel an account. These Terms and
          Conditions are governed and interpreted under the laws of the
          Colombian legal system. If any provision of this document is declared
          illegal or inapplicable, it shall not affect the validity and
          enforceability of any remaining provisions. You agree that your
          account is not transferable and all rights to the content Facebook
          profile or account cease with the death of the user. This document is
          subject to changes and modifications by Okii SAS at any time, which
          will be published on the website www.holiapp.live
        </p>
        <p>
          DEFENCES OF LIABILITY Usury acknowledge and agree that Okii SAS, its
          subsidiaries, branches and partners have directly or indirectly
          liability for any loss or damage caused as a result of: a. Any
          incorrect content or must not be posted by users via application; b.
          Behavior either online with the application or offline from any user;
          c. Any error, omission, interruption, disruption or delay in
          transmission or communication between users; c. Any problems, failures
          or technical malfunction related to the provision of the Internet or
          in connection with the device using the user. According to the laws,
          Holi app and Okii SAS offer their services, without giving warranty
          regarding the quality or level of satisfaction with respect to
          information or particular purpose for which the application is used.
          Additional, accessed by risk of user to any material downloaded or
          otherwise obtained through the use of the application, being the only
          responsible, giving way to any claim or action with respect to any
          damage to your mobile device, computer, access internet or loss of
          data. All information you make known to third parties solely is the
          same responsibility. Okii SAS not warrant the accuracy or reliability
          of any content provided through the application by users of the same.
          The application may contain advertisements and / or promotions offered
          by third parties and links to other websites, Okii SAS is not
          responsible for the content thereof. Any dispute, disagreement or
          conflict that is generated by the conclusion, execution,
          interpretation and termination of this agreement or those that are
          generated from the use of the application, you agree to be submitted
          to an arbitration tribunal, in order to give solution final by an
          arbitration award that makes res judicata and enforceable present
          according to the following rules: 1. The arbitral tribunal shall
          consist of three (3) arbitrators. 2. Such arbitrators shall be
          qualified lawyers registered. 3. The arbitrators shall be chosen by
          lot from the list of arbitrators of the Chamber of Commerce of Bogota.
          4. The arbitration will be institutional. 5. The arbitration center
          designed to take effect this arbitration is the Arbitration and
          Conciliation Center of the Chamber of Commerce of Bogota. 6. The
          arbitrators will decide in law. 7. Costs and agencies that are caused
          in the arbitration shall be borne entirely by the losing party. 8. A
          contract this clause and Colombian laws apply, particularly the
          provisions of the law 1563 of 2012, the provisions of the General
          Process Code and other related regulations.
        </p>
        <p>
          PRIVACY POLICY We are committed to protecting your privacy, ensuring
          that any information (full name, e-mail, personal description,
          information about gender, preferences, etc.) provide us will be kept
          safe. The only information may be shared with third parties is that
          the user has agreed to share in the application permissions. In order
          to register Holi asks the user to log in with your Gmail or Facebook,
          in doing so, you authorize us to access the information found on
          Facebook, according to the privacy settings that handles the user in
          this network will be asked on that information as public profile, your
          email address, interests, tastes, gender, birthday, education and
          employment profile, relationship and photos. Eventually the user to
          allow the location information from your device to download or use the
          application will be required. This document is an integral part of the
          Terms and Conditions. By accepting the Terms and Conditions you accept
          the Privacy Policy contained herein. If you do not agree with this
          Privacy Policy do not use the Site. Personal data requested are
          intended: Get an efficient communication regarding the application
          services. Provide our services and products. Report on new products or
          services that are related to Holi. Report changes in our services to
          evaluate the quality of service. Conduct internal research on consumer
          habits. Assignment database. So, we inform you that in accordance with
          the provisions of the Law 1581 of 2012 (Habeas Data), its Regulatory
          Decree 1377 of 2013 and other provisions and additional rules,
          information and personal data to be obtained by accessing application
          services will be collected and encrypted in a database in order that
          noted above for a period counted from the day you accept these terms
          and conditions. The database has security measures necessary for the
          proper conservation of data. By accepting, you as owner expressly
          authorizes the processing of data for the purpose mentioned. We remind
          the right that the responsibility of access at any time to the data
          supplied and to request correction, update, delete, or more
          information is not submitted, under the terms established by Law 1581
          of 2012, directing by writing mail: support@holiapp.live indicating
          the following information: Name and surname Address (for notification
          purposes) Petition Alleging particular application Date If you. Is ARE
          not in accordance with the current Privacy Policy please do not use
          the Site.
        </p>
        <h4>Contact: support@holiapp.live</h4>
      </div>
    </div>
  );
};
export default Terms;
